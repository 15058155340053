import React from "react";
import classNames from "classnames";
import { Container, Text } from "@atoms";

const CardCopy = ({ color, copy, overlap }) => {
  return (
    <Container
      className={classNames({
        "-mt-10 sm:-mt-20": overlap,
      })}
    >
      {overlap && <div className="absolute inset-x-0 h-10 bg-black sm:h-20" />}
      <div
        className={classNames(
          "relative z-10 rounded-lg px-4 py-8 sm:px-6 md:px-8 lg:py-12 lg:px-36 xl:py-16 xl:px-48",
          {
            "bg-pink": color === "pink",
            "bg-purple": color === "purple",
            "bg-teal": color === "teal",
          }
        )}
      >
        <Text
          className={classNames(
            "text-center font-heading text-1.5xl font-bold leading-tighter sm:text-[2.4rem]",
            {
              "bold-teal text-white": color === "pink" || color === "purple",
              "bold-white": color === "teal",
            }
          )}
        >
          {copy}
        </Text>
      </div>
    </Container>
  );
};

CardCopy.defaultProps = {
  overlap: false,
};

export default CardCopy;
